// material-ui
import { Box, Typography } from "@mui/material";
import { getCookie } from "../../../../../store/Cookie";
// project import
import NavGroup from "./NavGroup";
import menuItem from "../../../../../menu-items";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const coreUser = getCookie("coreUser");
    const items = coreUser && coreUser.coreRole === "MASTER" ? menuItem.items : menuItem.fmItems;
    const navGroups = items.map((item) => {
        switch (item.type) {
            case "group":
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
