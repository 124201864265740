import React, { useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";

// material-ui
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    Link,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    Box,
    createTheme,
    ThemeProvider,
    Container,
    CssBaseline,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
// cookie
import { setCookie } from "../../../store/Cookie";

// project import
import FirebaseSocial from "./FirebaseSocial";
import AnimateButton from "../../../components/@extended/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

// ============================|| FIREBASE - LOGIN ||============================ //
const theme = createTheme();

const AuthLogin = () => {
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    // not yet
    const MineisLogin = (email: string) => {
        setCookie("currentEmail", email, { path: "/" });
        window.location.href = "/dashboard";
        navigate("/dashboard");
    };

    useEffect(() => {
        setCookie("coreUser", "", { path: "/" });
        setCookie("accessToken", "", { path: "/" });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 25,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email("Must be a valid email")
                                .max(255)
                                .required("Email is required"),
                            password: Yup.string().max(255).required("Password is required"),
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setStatus({ success: false });
                                setSubmitting(false);
                            } catch (error: any) {
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    {/* <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login">Email Address</InputLabel>
                                            <OutlinedInput
                                                id="email-login"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder="Enter email address"
                                                fullWidth
                                                error={Boolean(touched.email && errors.email)}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="password-login">Password</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                error={Boolean(touched.password && errors.password)}
                                                id="-password-login"
                                                type={showPassword ? "text" : "password"}
                                                value={values.password}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showPassword ? (
                                                                <EyeOutlined />
                                                            ) : (
                                                                <EyeInvisibleOutlined />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder="Enter password"
                                            />
                                            {touched.password && errors.password && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-password-login"
                                                >
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sx={{ mt: -1 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={(event) =>
                                                            setChecked(event.target.checked)
                                                        }
                                                        name="checked"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label={
                                                    <Typography variant="inherit">
                                                        Keep me sign in
                                                    </Typography>
                                                }
                                            />
                                            <Link
                                                variant="inherit"
                                                component={RouterLink}
                                                to=""
                                                color="text.primary"
                                            >
                                                Forgot Password?
                                            </Link>
                                        </Stack>
                                    </Grid>
                                    {errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={true}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => MineisLogin(values.email)}
                                            >
                                                Login
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider>
                                            <Typography variant="caption"> Login with</Typography>
                                        </Divider>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <FirebaseSocial values={values} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            {process.env.REACT_APP_VER}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default AuthLogin;
